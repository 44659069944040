import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from '@mui/icons-material/FilterList';
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Checkbox, Divider, Drawer, FormControlLabel, List, ListItem, Menu, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import SDTextField from "../Supporting files/SDTextField/SDTextField";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";
import ProductCard from "../components/ProductCard";
import ProductsList from "../components/ProductsList";
import { transformProducts } from '../Supporting files/HelpingFunction';

function valuetext(value) {
  return `${value}°C`;
}

const ProductsData = () => {
  const tab = true;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get("categoryId");
  const [value, setValue] = useState([0, 5000]);
  const [productStats, setproductStats] = useState([]);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [allProducts, setallProducts] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [profileDrop, setProfileDrop] = useState(null);
  const openProfile = Boolean(profileDrop);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const closeProfileDrop = () => {
    setProfileDrop(null);
  };
  const openProfileDrop = (event) => {
    setProfileDrop(event.currentTarget);
  };

  useEffect(() => {
    let catId = [...checkedCategories];
    catId.push(categoryId);
    setCheckedCategories(catId);
  }, [categoryId]);

  const handleCheckboxChange = (id) => {
    setCheckedCategories((prevCheckedCategories) => {
      const newCheckedCategories = prevCheckedCategories.includes(id)
        ? prevCheckedCategories.filter((categoryId) => categoryId !== id)
        : [...prevCheckedCategories, id];
      return newCheckedCategories;
    });
  };

  const handleColorClick = (color) => {
    setSelectedColors((prevSelectedColors) =>
      prevSelectedColors.includes(color)
        ? prevSelectedColors.filter((selectedColor) => selectedColor !== color)
        : [...prevSelectedColors, color]
    );
  };
  useEffect(() => {
    GetProductStates();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const GetProductStates = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getProductState);
      setValue([0, response.response.maxPrice]);
      setproductStats(response.response);
    } catch (error) {
      console.error("Error fetching getAllProducts:", error.message);
    }
  };
const GetAllProduct = async () => {
  try {
    const categories = checkedCategories
      .filter((category) => category) // Filter out any empty or invalid values
      .join(",");
    const colors = selectedColors.map(encodeURIComponent).join(",");
    const min = value[0];
    const max = value[1];
    let query = `?min=${min}&max=${max}`;
    
    // If colors exist, append them to the query
    if (colors && colors.length > 0) {
      query += `&colors=${colors}`;
    }
    // If categories exist, append them to the query
    if (categories) {
      query += `&categories=${categories}`;
    }

    const url = `${URL_CONSTANTS.getAllProducts}${query}`;

    const response = await Get(url);
    const individualProducts = transformProducts(response.response.products);
    setallProducts(individualProducts);
    setIsLoader(false);
  } catch (error) {
    console.error("Error fetching getAllProducts:", error.message);
  }
};

  useEffect(() => {
    GetAllProduct();
    const handler = setTimeout(() => {
      setIsInitialLoad(false);
    }, 2000);

    return () => {
      clearTimeout(handler);
    };
  }, []);

  useEffect(() => {
    if (isInitialLoad) return;
    const handler = setTimeout(() => {
      GetAllProduct();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [checkedCategories, selectedColors, value]);

  const [openFilter, setOpenFilter] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 6999]);

  const toggleDrawer = () => setOpenFilter(!openFilter);


  return (
    <>
      {isLoader && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="">
          <Grid container padding={0} columnSpacing={2} rowSpacing={2}>
            <Grid xs={12} sm={12} md={8} lg={9} className="lookSection">
              <Box className="look">
                <>
                  <IconButton onClick={toggleDrawer} aria-label="Filter">
                    <FilterListIcon />
                    <Typography variant="button" sx={{ ml: 1 }}>
                      Filter
                    </Typography>
                  </IconButton>

                  <Drawer
                    anchor="left"
                    open={openFilter}
                    onClose={toggleDrawer}
                    className="filter_drawer"
                  >
                    <div className="full_Div">
                      <div className="head">
                        <Typography variant="h6">FILTER</Typography>
                        <IconButton onClick={toggleDrawer}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                      <Divider sx={{ my: 2 }} />

                      <Typography className="subtitle1">Price</Typography>
                      <Slider
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={+productStats?.maxPrice}
                        sx={{ color: "#8E282B" }}
                      />
                      <Typography
                        className="subtitle1"
                        style={{ fontSize: 14 }}
                        align="center"
                      >
                        Price: ₹{0} - ₹{+productStats?.maxPrice}
                      </Typography>
                      <Divider sx={{ my: 2 }} />

                      <Typography className="subtitle1">
                        Select Category
                      </Typography>
                      <Box className="panel_content">
                        <List className="categories">
                          {productStats?.categories?.map((data, i) => (
                            <ListItem key={i}>
                              <FormControlLabel
                                key={data._id}
                                control={
                                  <Checkbox
                                    checked={checkedCategories.includes(
                                      data._id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(data._id)
                                    }
                                    name={data.title}
                                    sx={{
                                      color: "#8E282B",
                                      "&.Mui-checked": {
                                        color: "#8E282B",
                                      },
                                    }}
                                  />
                                }
                                label={data.title}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Typography className="subtitle1">
                        Select Color
                      </Typography>
                      <Box className="panel_content">
                        <div className="colors pt-6 pb-2 px-1">
                          {productStats?.colors?.map((color) => (
                            <div
                              key={color}
                              className={`hex ${
                                selectedColors.includes(color) ? "selected" : ""
                              }`}
                              style={{ background: color }}
                              onClick={() => handleColorClick(color)}
                            ></div>
                          ))}
                        </div>
                      </Box>
                    </div>
                  </Drawer>
                </>
                <Grid
                  container
                  padding={0}
                  columnSpacing={2}
                  rowSpacing={2}
                  className="look_container"
                >
                  {allProducts?.length > 0 &&
                    allProducts?.map((product) => {
                      return <ProductCard product={product} />;
                    })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"Shop "}
          text2={"the New Stock"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
        />
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"Top"}
          text2={"Trending Items"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />
        <Footer />
      </Box>
    </>
  );
};

export default ProductsData;
