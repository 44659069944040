import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Get } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import "../commonSCSS/common.scss";
import BannerSlider from "../components/BannerSlider";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ProductsList from "../components/ProductsList";
import { userRoutes } from "../Routes/UserRoutes";
import { capitalizeFirstLetter } from "../Supporting files/HelpingFunction";
import Loader from "./../components/Loader/Loader";
import { ADMINURL_CONSTANTS } from "../Admin/AdminApiUrl/AdminApiUrl";

const DashboardData = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [newArrivals, setNewArrivals] = useState([]);
  const [newBlogs, setNewBlogs] = useState([]);
  const [featuredCollection, setFeaturedCollection] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);
  const [selectedFeatureType, setselectedFeatureType] = useState("1");
  const [loading, setLoading] = useState(false);
  const [allCollectionData, setallCollectionData] = useState([]);

  useEffect(() => {
    fetchCategories();
    getNewArrival();
    getBestSeller();
    getFeaturedCollection();
    getBlog();
    GetAllCollections();
  }, []);


console.log("allCollectionData", allCollectionData);

  const homeSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const styleSliderSettings = {
    dots: true,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 18800, // LG screens
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1880, // LG screens
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1380, // MD screens
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 830, // XS screens
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // XS screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const testimonialSettings = {
    className: "center",
    centerMode: true,
    // infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    autoplay: false,
    speed: 1000,
    dots: true,
    responsive: [
      {
        breakpoint: 3000, // LG screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      // {
      //   breakpoint: 1280, // LG screens
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 1,
      //   },
      // },
      // {
      //   breakpoint: 960, // MD screens
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 1,
      //   },
      // },
      {
        breakpoint: 600, // XS screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

    const GetAllCollections = async (page = 0, pageSize = 25) => {
      try {
        const response = await Get(
          `${URL_CONSTANTS.GetAllCollections}?page=${
            page + 1
          }&pageSize=${pageSize}`
        );
        setallCollectionData(response.response);
      } catch (error) {
        console.error("Error ", error.message);
      }
    };

  //Get all category list
  const fetchCategories = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getAllCategories);
      if (response.response?.length > 0) {
        setCategories(response.response);
      }
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  //Get new arrival
  const getNewArrival = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getNewArrival);
      setNewArrivals(response.response);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };
  //Get new blog
  const getBlog = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getBlogs);
      setNewBlogs(response.response);
    } catch (error) {
      console.error("Error fetching Blogs:", error.message);
    }
  };
  //Get getBestSeller
  const getBestSeller = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getBestSeller);
      setBestSellers(response.response.products);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };

  //Get getFeaturedCollection
  const getFeaturedCollection = async () => {
    try {
      const response = await Get(URL_CONSTANTS.getfeatured);
      setFeaturedCollection(response.response);
    } catch (error) {
      console.error("Error fetching getNewArrival:", error.message);
    }
  };

  const getSliderContent = () => {
    let data = [];
    switch (selectedFeatureType) {
      case "1":
        data = newArrivals;
        break;
      case "2":
        data = bestSellers;
        break;
      case "3":
        data = featuredCollection;
        break;
      default:
        data = [];
    }
    const styleSliderSettings = {
      dots: true,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      cssEase: "linear",
      responsive: [
        { breakpoint: 18800, settings: { slidesToShow: 4, }, },
        { breakpoint: 1880, settings: { slidesToShow: 4, }, },
        { breakpoint: 1380, settings: { slidesToShow: 3, }, },
        { breakpoint: 830, settings: { slidesToShow: 2, }, },
        { breakpoint: 600, settings: { slidesToShow: 1, }, },
      ],
    };
    return (
      <Slider {...styleSliderSettings}>
        {data.length > 0 &&
          data.slice(0, 4).map((item, index) => (
            <Box
              key={index}
              className="img_slide"
              onClick={() => {
                if (selectedFeatureType === "2") {
                  navigate(userRoutes.bestSeller);
                } else if (selectedFeatureType === "3") {
                  navigate(userRoutes.featuredProduts);
                } else {
                  navigate(userRoutes.newArrivals);
                }
              }}
            >
              <img
                loading="eager"
                className="slider_image"
                src={item.variants[0].images[0]}
                alt={`Style ${index + 1}`}
              />
              <div className="size_container">
                {item.variants.map((colors, sizeIndex) => (
                  <div
                    key={sizeIndex}
                    className="color_box"
                    style={{ backgroundColor: colors.color }}
                  ></div>
                ))}
              </div>
            </Box>
          ))}
      </Slider>
    );
  };

  const collectionSlider = () => {
    const styleSliderSettings = {
      dots: true,
      slidesToScroll: 1,
      autoplay: false,
      speed: 2000,
      autoplaySpeed: 5000,
      cssEase: "linear",
      responsive: [
        { breakpoint: 18800, settings: { slidesToShow: 3 } },
        { breakpoint: 1380, settings: { slidesToShow: 3 } },
        { breakpoint: 830, settings: { slidesToShow: 2 } },
        // { breakpoint: 600, settings: { slidesToShow: 1 } },
      ],
    };
    return (
      <>
        <Slider {...styleSliderSettings}>
          {allCollectionData.length > 0 &&
            allCollectionData.slice(0, 5).map((item, index) => (
              <Box key={index} className="slider-item">
                <img
                  loading="eager"
                  className="slider-image"
                  src={item.collectionImage}
                  alt={`Style ${index + 1}`}
                />
                <div className="col_data">
                  <div class="contentCollection">
                    <h3>{item.collectionName}</h3>
                    {/* <p>COLLECTIONS</p> */}
                    <button className="view-collection-button" onClick={()=>{navigate(`${userRoutes.collectionProducts}?collectionId=${item._id}`);}}>
                      VIEW COLLECTION
                    </button>
                  </div>
                </div>
              </Box>
            ))}
        </Slider>
      </>
    );
  }

  return (
    <>
      {loading && <Loader />}
      <Header />

      <BannerSlider />
      <Box className="mainWrapper">
        <Box className="shopSection">
          {/* Shop By Categories */}
          <Box className="shop_box">
            <h2 className="theme_heading">
              Shop <span>By Categories</span>
            </h2>
            <Box className="new_category">
              <Grid container padding={0} columnSpacing={2} rowSpacing={2}>
                <Grid xs={7} md={7} className="top_one">
                  <div className="heading_btn">
                    <h2 className="heading">{categories[0]?.title}</h2>
                    <div className="ctaBtn">
                      <Button
                        className="borderBtn"
                        onClick={() => {
                          navigate(
                            `${userRoutes.products}?categoryId=${categories[0]?._id}`
                          );
                        }}
                      >
                        Shop Now
                      </Button>
                    </div>
                  </div>
                  <div className="cat_images">
                    <img
                      src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Group%201000001774%20%281%29.png-1729706509477.png"
                      loading="eager"
                    />
                  </div>
                </Grid>
                <Grid xs={12} md={5} className="top_two">
                  <div className="topp">
                    <div className="heading_btn" style={{ left: "30%" }}>
                      <h2 className="heading">{categories[1]?.title}</h2>
                      <div className="ctaBtn">
                        <Button
                          className="borderBtn"
                          onClick={() => {
                            navigate(
                              `${userRoutes.products}?categoryId=${categories[1]?._id}`
                            );
                          }}
                        >
                          Shop Now
                        </Button>
                      </div>
                    </div>
                    <div className="cat_images">
                      <img
                        src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Mask%20group.png-1729706660114.png"
                        loading="eager"
                      />
                    </div>
                  </div>
                  <div className="topp">
                    <div className="heading_btn">
                      <h2 className="heading">{categories[2]?.title}</h2>
                      <div className="ctaBtn">
                        <Button
                          className="borderBtn"
                          onClick={() => {
                            navigate(
                              `${userRoutes.products}?categoryId=${categories[2]?._id}`
                            );
                          }}
                        >
                          Shop Now
                        </Button>
                      </div>
                    </div>
                    <div className="cat_images">
                      <img
                        src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Mask%20group%20%282%29.png-1729706791295.png"
                        loading="eager"
                      />
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} md={5} className="top_one">
                  <div className="heading_btn btn_right">
                    <h2 className="heading">{categories[3]?.title}</h2>
                    <div className="ctaBtn">
                      <Button
                        className="borderBtn"
                        onClick={() => {
                          navigate(
                            `${userRoutes.products}?categoryId=${categories[3]?._id}`
                          );
                        }}
                      >
                        Shop Now
                      </Button>
                    </div>
                  </div>
                  <div className="cat_images">
                    <img
                      src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Mask%20group%20%281%29.png-1729706860146.png"
                      loading="eager"
                    />
                  </div>
                </Grid>
                <Grid xs={12} md={7} className="top_one">
                  <div className="heading_btn btn_right">
                    <h2 className="heading">{categories[4]?.title}</h2>
                    <div className="ctaBtn">
                      <Button
                        className="borderBtn"
                        onClick={() => {
                          navigate(
                            `${userRoutes.products}?categoryId=${categories[4]?._id}`
                          );
                        }}
                      >
                        Shop Now
                      </Button>
                    </div>
                  </div>
                  <div className="cat_images">
                    <img
                      src="https://ishnoor-ecomm.s3.ap-southeast-2.amazonaws.com/Frame%201171280377.png-1729706860147.png"
                      loading="eager"
                    />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* our collection slider */}
          <Box className="collections_section">
            <Grid container>
              <Grid xs={12} lg={4} xl={3} className="styleDiv">
                <div className="leftBox">
                  <h6>MOST POPULAR</h6>
                  <h4>Our Collection</h4>
                  <p>
                    Unveil our extensive collection at Ishnoor, featuring new
                    additions!
                  </p>
                  <div className="ctaBtn">
                    <Button variant="contained" className="submitBtn">
                      shop now
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} lg={8} xl={9} className="react_custom_slider">
                {collectionSlider()}
              </Grid>
            </Grid>
          </Box>

          {/* Shop The Look */}
          <ProductsList
            apiUrl={URL_CONSTANTS.getAllProducts}
            text1={"Shop"}
            text2={"The Look"}
            productToShow={8}
          />

          {/* Style Spotlight */}
          <Box className="style">
            <Grid container>
              <Grid xs={12} lg={3} className="styleDiv">
                <h2 className="theme_heading_xl">
                  Style <span>Spotlight</span>
                </h2>
                <h5
                  className={
                    selectedFeatureType === "1" ? "highlight" : "normal"
                  }
                  style={{ position: "relative" }}
                  onClick={() => setselectedFeatureType("1")}
                >
                  New Arrivals
                </h5>
                <h5
                  className={
                    selectedFeatureType === "2" ? "highlight" : "normal"
                  }
                  style={{ position: "relative" }}
                  onClick={() => setselectedFeatureType("2")}
                >
                  Best Seller
                </h5>
                <h5
                  className={
                    selectedFeatureType === "3" ? "highlight" : "normal"
                  }
                  style={{ position: "relative" }}
                  onClick={() => setselectedFeatureType("3")}
                >
                  Featured Collection
                </h5>
                <div className="ctaBtn">
                  <Button
                    variant="contained"
                    className="submitBtn"
                    onClick={() => {
                      if (selectedFeatureType === "1") {
                        navigate(userRoutes.newArrivals);
                      } else if (selectedFeatureType === "2") {
                        navigate(userRoutes.bestSeller);
                      } else if (selectedFeatureType === "3") {
                        navigate(userRoutes.featuredProduts);
                      }
                    }}
                  >
                    View All
                  </Button>
                </div>
              </Grid>
              <Grid xs={12} lg={9} className="style_slider">
                {getSliderContent()}
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Updated Inventory */}
        <ProductsList
          apiUrl={URL_CONSTANTS.getNewArrival}
          text1={"Browse Our"}
          text2={"Updated Inventory"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.newArrivals}
          bg={true}
        />
        {/* Top BestSeller */}
        <ProductsList
          apiUrl={URL_CONSTANTS.getBestSeller}
          text1={"Our"}
          text2={"Top BestSeller"}
          productToShow={8}
          buttonRedirectionUrl={userRoutes.bestSeller}
        />
        {/* blogs */}
        <Box className="blogSection">
          <h2 className="theme_heading">
            From The <span>Blog</span>
          </h2>
          <Box>
            <Grid container spacing={3}>
              {newBlogs?.slice(-3).map((data) => (
                <Grid xs={12} sm={6} lg={4} className="blog">
                  {/* <div className="date"> Feb/2/2024 </div> */}
                  <img loading="eager" src={data.image} alt="blog" />
                  <div className="info">
                    <h6>
                      {data.title?.length > 35
                        ? data.title.slice(0, 35).toUpperCase() + "..."
                        : data.title.toUpperCase()}
                    </h6>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          data.description?.length > 200
                            ? capitalizeFirstLetter(
                                data.description.slice(0, 200)
                              ) + "..."
                            : capitalizeFirstLetter(data.description),
                      }}
                    ></p>

                    <Grid xs={12} className="ctaBtn p-0">
                      <Button
                        variant="contained"
                        className="borderBtn"
                        onClick={() =>
                          navigate(
                            `${userRoutes.blogDetail}?blogId=${data?._id}`
                          )
                        }
                      >
                        Read More
                      </Button>
                    </Grid>
                  </div>
                </Grid>
              ))}
            </Grid>
            <Grid xs={12} className="ctaBtn text-center my-6 ">
              <Button
                onClick={() => {
                  navigate(userRoutes.blogs);
                }}
                variant="contained"
                className="submitBtn"
              >
                View All
              </Button>
            </Grid>
          </Box>
        </Box>
        {/* instagram  */}
        <Box className="followSection">
          <h2 className="theme_heading">
            Follow Us On <span>Instagram</span>
          </h2>
          <Box>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid xs={6} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img loading="eager" src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  loading="eager"
                  src="images/follow1.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
              <Grid xs={6} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img loading="eager" src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  loading="eager"
                  src="images/follow2.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
              <Grid xs={6} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img loading="eager" src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  loading="eager"
                  src="images/follow1.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
              <Grid xs={6} sm={6} md={4} lg={3} className="follow">
                <div className="logo">
                  <img loading="eager" src="images/instaLogo.png" alt="insta" />
                </div>
                <img
                  loading="eager"
                  src="images/follow2.svg"
                  alt="insta"
                  className="follow-img"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* Testimonials */}
        <Box className="testimonialSection">
          <h2 className="theme_heading mb-0">Testimonials</h2>
          <Slider {...testimonialSettings}>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid xs={12} lg={4} className="user">
                  <img loading="eager" src="images/james1.svg" alt="james" />
                </Grid>
                <Grid xs={12} lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.1</h2>
                </Grid>
              </Grid>
            </Box>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid xs={12} lg={4} className="user">
                  <img loading="eager" src="images/james2.svg" alt="james" />
                </Grid>
                <Grid xs={12} lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.2</h2>
                </Grid>
              </Grid>
            </Box>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid xs={12} lg={4} className="user">
                  <img loading="eager" src="images/james1.svg" alt="james" />
                </Grid>
                <Grid xs={12} lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.3</h2>
                </Grid>
              </Grid>
            </Box>
            <Box className="testBox">
              <Grid container columnSpacing={3} paddingLeft={"12px"}>
                <Grid xs={12} lg={4} className="user">
                  <img loading="eager" src="images/james2.svg" alt="james" />
                </Grid>
                <Grid xs={12} lg={8} className="userData pl-0">
                  <p>
                    Absolutely thrilled with my shopping experience! The user
                    interface is seamless, and the customer service is
                    outstanding. Highly recommend this e-commerce platform!
                  </p>
                  <div className="stars">
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                    <img loading="eager" src="images/star.svg" />
                  </div>
                  <div className="devider"></div>
                  <h2>James K.4</h2>
                </Grid>
              </Grid>
            </Box>
          </Slider>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default DashboardData;
