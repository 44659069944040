import { Box, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { Get } from "../Api/api";
import { userRoutes } from "../Routes/UserRoutes";
import {
  formatDate,
  formatOrderStatus,
  truncateDescription,
} from "../Supporting files/HelpingFunction";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";

const Orders = () => {
  const navigate = useNavigate();
  const [allOrdersData, setallOrdersData] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getAllOrders();
  }, []);

  const getAllOrders = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.ALLOrders}`);
      setallOrdersData(response.response);
      setLoading(false);
    } catch (error) {
      console.error("Error ", error.message);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="orderSection">
          {allOrdersData?.length > 0 &&
            allOrdersData?.map((order) => {
              return (
                <Box className="section">
                  <Grid container padding={0}>
                    <Grid xs={12} className="del_status mb-4">
                      <h3 className="Delivered mb-3">
                        {formatOrderStatus(order?.deliveryStatus)}
                      </h3>
                      <p className="date">On {formatDate(order?.updatedAt)}</p>
                    </Grid>
                    <Grid xs={12} className="del_details">
                      <img loading="eager" 
                        src="images/order_next.svg"
                        alt="r"
                        onClick={() =>
                          navigate(`${userRoutes.orderDetail}?id=${order?._id}`)
                        }
                        className="order_img"
                      />
                      <img loading="eager" 
                        src={
                          order?.products[0]?.productDetail?.variants?.images[0]
                        }
                        alt="r"
                        className="del_img"
                      />
                      <div className="details pr-10 w-100">
                        <h6 className="title mb-3">
                          {order?.products[0]?.productDetail?.title}
                        </h6>
                        <h6 className="desc mb-3">
                          {truncateDescription(
                            order?.products[0]?.productDetail?.description,
                            200
                          )}
                        </h6>
                      </div>
                    </Grid>
                    {/* <Grid xs={12} >
                      <Rating
                        className="mb-2"
                        name="simple-controlled"
                        value={rat}
                        onChange={(event, newValue) => {
                          setRat(newValue);
                        }}
                      />
                    </Grid> */}
                    <Grid xs={12} className="Exchange">
                      Exchange/Return window will closed after 10 days of
                      Delivery.
                    </Grid>
                    {/* <Grid
                      xs={12}
                      className="review"
                      style={{ cursor: "pointer" }}
                      onClick={() => setPopupOpen(true)}
                    >
                      Write Review
                    </Grid> */}
                  </Grid>
                </Box>
              );
            })}
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Orders;
