import React from "react";
import { Route, Routes } from "react-router-dom";
import AddCategory from "../Admin/AddCategory";
import AddProducts from "../Admin/AddProducts";
import AdminDashboard from "../Admin/AdminDashboard";
import AllBlogs from "../Admin/AllBlogs";
import AllCategories from "../Admin/AllCategories";
import AllOrders from "../Admin/AllOrders";
import AllProducts from "../Admin/AllProducts";
import AllUsers from "../Admin/AllUsers";
import BlogPost from "../Admin/BlogPost";
import { adminRoutes } from "./AdminRoutes";
import OrderDetails from "../Admin/OrderDetails";
import AllContact from "../Admin/AllContact";
import AddCollection from "../Admin/AddCollection";
import AllCollection from "../Admin/AllCollection";

const AdminRoutesData = () => {
  return (
    <>
      <Routes>
        <Route path={adminRoutes.homePage} element={<AdminDashboard />} />
        <Route path={adminRoutes.addProduct} element={<AddProducts />} />
        <Route path={adminRoutes.addCategory} element={<AddCategory />} />
        <Route path={adminRoutes.addCollection} element={<AddCollection />} />
        <Route path={adminRoutes.allCollection} element={<AllCollection />} />
        <Route path={adminRoutes.allProducts} element={<AllProducts />} />
        <Route path={adminRoutes.allCategories} element={<AllCategories />} />
        <Route path={adminRoutes.allUsers} element={<AllUsers />} />
        <Route path={adminRoutes.allOrders} element={<AllOrders />} />
        <Route path={adminRoutes.createBlog} element={<BlogPost />} />
        <Route path={adminRoutes.allBlogs} element={<AllBlogs />} />
        <Route path={adminRoutes.orderDetails} element={<OrderDetails />} />
        <Route path={adminRoutes.allContact} element={<AllContact />} />
      </Routes>
    </>
  );
};

export default AdminRoutesData;
