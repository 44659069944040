import React, { useEffect } from 'react';
import './Loader.scss';

const Loader = () => {
    // useEffect(() => {
    //     document.body.style.overflow = "hidden";
    // }, [])

    return (
        <div className="loader-container">
            <div className="loader">
                <span className="hour"></span>
                <span className="min"></span>
                <span className="circel"></span>
            </div>
        </div >
    );
}

export default Loader;