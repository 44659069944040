import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Zoom, Navigation, Pagination } from "swiper/modules";

// ImageSwiperWithZoom Component
export default function ImageSwiperWithZoom({ imageUrls, onClose }) {
  // State to track if zoomed in or not
  const [isZoomed, setIsZoomed] = useState(false);

  // Toggle zoom state on double click
  const handleDoubleClick = () => {
    setIsZoomed((prev) => !prev);
  };

  return (
    <div className="swiper-container">
      <button onClick={onClose} className="close-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          fill="white"
        >
          <path d="M12 10.586L16.95 5.636l1.414 1.414L13.414 12l5.95 5.95-1.414 1.414L12 13.414l-5.95 5.95-1.414-1.414L10.586 12 4.636 6.05 6.05 4.636z" />
        </svg>
      </button>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className="mySwiper"
      >
        {imageUrls.map((src, index) => (
          <SwiperSlide key={index}>
            <div
              className="swiper-zoom-container"
              onDoubleClick={handleDoubleClick} // Add double-click handler
            >
              <img
                src={src}
                alt={`Image ${index + 1}`}
                className={`swiper-image ${isZoomed ? "zoom-out" : "zoom-in"}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <style jsx>{`
        .swiper-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .swiper-zoom-container {
          width: 35%; /* Reduces the container size */
          height: 70%;
          display: flex;
          justify-content: center; /* Center the content horizontally */
          align-items: center; /* Center the content vertically */
          margin: auto; /* Centers the zoom container itself */
        }

        .swiper-slide {
          display: flex;
          justify-content: center; /* Ensures image is centered in slide */
          align-items: center;
        }

        .swiper-image {
          max-width: 100%; /* Restrict max width to keep image size contained */
          max-height: 100%;
          object-fit: contain;
        }

        .swiper-image.zoom-in {
          cursor: zoom-in;
        }

        .swiper-image.zoom-out {
          cursor: zoom-out;
        }

        .close-button {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 10;
          background: transparent;
          border: none;
          cursor: pointer;
        }

        .close-button:hover {
          opacity: 0.7;
        }
      `}</style>
    </div>
  );
}
