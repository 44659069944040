import React, { useState } from "react";
import { Container, TextField, Button, Typography, Box, Grid } from "@mui/material";
import { Post } from "../Api/api";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";
import toast from "react-hot-toast";
import ImageUploader from "../components/ImageUploader";
import RichTextEditor from "../components/RichTextEditor";

const BlogPost = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    authorName: "",
    image: "",
  });
    const [editorData, setEditorData] = useState(
      "<b>write product description here</b>"
    );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageUploadSuccess = (imageUrls) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: imageUrls[0],
    }));
  };
  const validateForm = () => {
    if (!formData.title.trim()) {
      toast.error("Title is required");
      return false;
    }
    if (!formData.description.trim()) {
      toast.error("Description is required");
      return false;
    }
    if (!formData.authorName.trim()) {
      toast.error("Author Name is required");
      return false;
    }
    if (!formData.image.trim()) {
      toast.error("Image is required");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const response = await Post(
        ADMINURL_CONSTANTS.postBlog,
        JSON.stringify(formData)
      );
      console.log("blog response:", response);
      toast.success("Blog post created successfully");
      setFormData({
        title: "",
        description: "",
        authorName: "",
        image: "",
      });
    } catch (error) {
      console.error("Error ", error);
      toast.error("Failed to create blog post");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create a Blog Post
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            fullWidth
          />
          <Grid item xs={12} sm={12}>
            <RichTextEditor
              content={editorData}
              onChange={(data) => {
                setFormData({ ...formData, description: data });
                setEditorData(data);
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: formData.description }} />
          </Grid>

          <TextField
            label="Author Name"
            name="authorName"
            value={formData.authorName}
            onChange={handleChange}
            required
            fullWidth
          />
          <ImageUploader onUploadSuccess={handleImageUploadSuccess} />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogPost;
