import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { Post } from "../Api/api";
import ImageUploader from "../components/ImageUploader";
import { ADMINURL_CONSTANTS } from "./AdminApiUrl/AdminApiUrl";

const AddCollection = () => {
  const [formValues, setFormValues] = useState({
    collectionName: "",
    image: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasError = false;

    if (!formValues.image) {
      hasError = true;
      return toast.error("Please select an image");
    }

    let dto = {
      collectionName: formValues.collectionName,
      collectionImage: formValues.image,
    };

    if (!hasError) {
      try {
        await Post(ADMINURL_CONSTANTS.AddCollection, dto);
        alert("Collection added successfully");
        setFormValues({ collectionName: "", categoryDescription: "", image: "" });
      } catch (error) {
        console.error("Submission error", error);
      }
    }
  };

  const handleImageUploadSuccess = (imageUrls) => {
    setFormValues((prevFormData) => ({
      ...prevFormData,
      image: imageUrls[0],
    }));
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add New Collection
        </Typography>
        <form onSubmit={handleSubmit} method="post">
          <TextField
            fullWidth
            label="Collection Name"
            name="collectionName"
            value={formValues.collectionName}
            onChange={handleChange}
            margin="normal"
            required
            error={!!errors.collectionName}
            helperText={errors.collectionName}
          />

          <ImageUploader onUploadSuccess={handleImageUploadSuccess} />

          {errors.submit && (
            <Typography color="error" variant="body2">
              {errors.submit}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Add Collection
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AddCollection;
