import { TextField } from "@mui/material";

const SDTextField = ({
  title,
  value,
  attrName,
  value_update,
  cls,
  style,
  disable,
  required,
  type = "text",
  helperText,
  warn_status,
}) => {
  return (
    <>
      <div className={`inputField`} style={style}>
        <TextField
          className={cls}
          type={type}
          label={title}
          value={value}
          value_update={value_update}
          disable={disable}
          onChange={(e) => {
            value_update(attrName, e.target.value);
          }}
          required={required}
        />
        {warn_status == true ? (
          <span
            className="error"
          >
            {helperText}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default SDTextField;
