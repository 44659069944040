import {
  Backdrop,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../commonSCSS/modals.scss";
import {
  calculateDiscountPercentage,
  capitalizeFirstLetter,
  formatCurrency,
} from "../../Supporting files/HelpingFunction";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../Redux/Slice/UserSlice";
import { Post, Put } from "../../Api/api";
import { URL_CONSTANTS } from "../../Api/ApiUrl";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

const QuickView = ({
  open,
  handleClose,
  productItem,
  selectedVarients,
  selectedSizes,
}) => {
  const dispatch = useDispatch();

  const [selectedVarientsId, setselectedVarientsId] = useState("");
  const [selectedSizesId, setselectedSizesId] = useState("");
  const cartData = useSelector((state) => state?.user?.cart);
  const [is_CartExist, setis_CartExist] = useState(false);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
  };
  // Set default variant and size when the modal opens
  useEffect(() => {
    if (open && productItem?.variants?.length > 0) {
      const defaultVariant = productItem.variants[0]; // First variant by default
      setselectedVarientsId(selectedVarients || defaultVariant._id); // Use passed prop or default to first variant
      setselectedSizesId(selectedSizes || defaultVariant.sizes[0]?._id); // Use passed prop or default to first size
    }
  }, [open, productItem, selectedVarients, selectedSizes]);

  // Find the selected variant
  const selectedVariant = productItem?.variants?.find(
    (variant) => variant._id === selectedVarientsId
  );
  const addToCart = async () => {
    let dto = {
      productId: productItem._id,
      variantId: selectedVarientsId,
      sizeId: selectedSizesId,
      quantity: 1,
    };
    try {
      const response = await Post(
        `${URL_CONSTANTS.AddCartItems}`,
        JSON.stringify(dto)
      );
      dispatch(updateCart(response.response.products));
      toast.success("Cart updated successfully");
    } catch (error) {
      console.error("Error ", error);
    }
  };
  const removeFromCart = async () => {
    let dto = {
      productId: productItem._id,
      variantId: selectedVarientsId,
      sizeId: selectedSizesId,
      quantity: 1,
    };
    try {
      const response = await Put(
        `${URL_CONSTANTS.removeCartItems}`,
        JSON.stringify(dto)
      );
      toast.success("Cart updated successfully");
      dispatch(updateCart(response.response.products));
    } catch (error) {
      console.error("Error ", error);
    }
  };

  useEffect(() => {
    const cartItem = cartData?.find(
      (productData) => productData.sizeId === selectedSizesId
    );
    setis_CartExist(!!cartItem);
  }, [cartData, selectedSizesId]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{ backdrop: { timeout: 500 } }}
      className="modalWrapper"
    >
      <Box className="modalInner xl-width quick_view_modal">
        {/* Close button */}
        <IconButton
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#000",
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={3} className="quick_container">
          <Grid xs={12} md={7}>
            <Slider {...sliderSettings}>
              {selectedVariant?.images?.length > 0 ? (
                selectedVariant?.images?.map((image, index) => (
                  <div
                    key={index}
                    style={{ height: "100%", position: "relative" }}
                  >
                    <img
                      src={image}
                      alt={productItem?.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))
              ) : (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <Typography>No Images Available</Typography>
                </div>
              )}
            </Slider>
          </Grid>
          <Grid xs={12} md={5}>
            <div className="all_detail">
              <h1 className="h1">
                {capitalizeFirstLetter(productItem?.title)}
              </h1>
              <h2 className="h2">
                {formatCurrency(
                  selectedVariant?.sizes.find(
                    (size) => size._id === selectedSizesId
                  )?.price ?? 0
                )}
                <span>
                  {formatCurrency(
                    selectedVariant?.sizes.find(
                      (size) => size._id === selectedSizesId
                    )?.mrp
                  )}
                </span>
                <span
                  style={{
                    textDecoration: "none",
                    color: "#8E282B",
                    fontWeight: "600",
                  }}
                >
                  {calculateDiscountPercentage(
                    selectedVariant?.sizes.find(
                      (size) => size._id === selectedSizesId
                    )?.mrp,
                    selectedVariant?.sizes.find(
                      (size) => size._id === selectedSizesId
                    )?.price ?? 0
                  )}
                  % off
                </span>
              </h2>
            </div>
            <div
              className="desc mb-6"
              dangerouslySetInnerHTML={{
                __html:
                  productItem?.short_description ?? productItem?.description,
              }}
            >
              {/* {`${productItem?.description.substring(0, 250)}...`} */}
            </div>
            {/* <div className="desc mb-6">
                {
                  productItem?.description
                  // ?.slice(0, 50)
                }
              </div> */}
            <div className="colorss mb-6">
              <h6>Select Color :</h6>
              {productItem?.variants?.map((variant) => {
                return (
                  <div
                    key={variant._id}
                    onClick={() => {
                      setselectedVarientsId(variant._id);
                      setselectedSizesId(variant?.sizes[0]?._id); // Default to the first size of the selected variant
                    }}
                    className={`color_all ${
                      variant._id === selectedVarientsId ? "selected" : ""
                    }`}
                    style={{ background: variant?.color }}
                  ></div>
                );
              })}
            </div>
            <div className="sizes mb-6">
              <h6>Size :</h6>
              <>
                {selectedVariant?.sizes?.map((size) => (
                  <div
                    key={size._id}
                    onClick={() => setselectedSizesId(size._id)}
                    className={`size_all ${
                      selectedSizesId === size._id ? "selected" : ""
                    }`}
                  >
                    {size.size}
                  </div>
                ))}
              </>
            </div>
            <Grid
              container
              padding={0}
              display={"flex"}
              justifyContent={"center"}
            >
              <div
                className="ctaBtn text-center mt-8"
                style={{
                  position: "absolute",
                  bottom: "16px",
                }}
              >
                {productItem?.isActive && is_CartExist ? (
                  <Button
                    onClick={removeFromCart}
                    variant="contained"
                    className="submitBtn"
                  >
                    Remove from Cart
                  </Button>
                ) : productItem?.isActive &&
                  productItem?.variants
                    ?.find((variant) => variant._id === selectedVarientsId)
                    ?.sizes.find((size) => size._id === selectedSizesId)
                    ?.quantity > 0 ? (
                  <Button
                    onClick={addToCart}
                    variant="contained"
                    className="submitBtn"
                  >
                    Add To Cart
                  </Button>
                ) : (
                  <Button variant="contained" className="submitBtn">
                    Sold out
                  </Button>
                )}
              </div>
              {/* <div
                  className="ctaBtn text-center mt-8"
                  style={{
                    position: "absolute",
                    bottom: "16px",
                  }}
                >
                  <Button className="submitBtn">Add to cart</Button>
                </div> */}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
const CustomArrow = ({ direction, onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 10,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      borderRadius: "50%",
      padding: "10px",
      color: "white",
      cursor: "pointer",
      [direction === "next" ? "right" : "left"]: "15px",
    }}
  >
    {direction === "next" ? "→" : "←"}
  </div>
);
export default QuickView;
