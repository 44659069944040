import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Shipping = () => {
  return (
    <>
      <Box className="mainWrapper">
        <Header />
        <Box className="our_blogs privacy">
          <Box>
            <h1>Shipping Policy</h1>
            <h2>
              Home/<span> Shipping Policy</span>
            </h2>
          </Box>
          <Box className="privacySection">
            <Grid container padding={0}>
              <Grid xs={12} className="mb-6">
                <h3 className="para mb-3">
                  • Ishnoor offers free shipping on prepaid orders over INR
                  899/- across India.
                </h3>
                <h3 className="para mb-3">
                  • Products are typically dispatched within 2-4 days of placing
                  your order.
                </h3>
                <h3 className="para mb-3">
                  • Once dispatched, your order will be delivered to your
                  address within 3-4 working days by our delivery partners.
                </h3>
                <h3 className="para mb-3">
                  • Each product undergoes a final quality check, hygiene check,
                  tagging, and packaging before shipping.
                </h3>
                <h3 className="para mb-3">
                  • Delivery will be made to the address provided by the
                  customer at the time of ordering. For any modifications,
                  please email us at{" "}
                  <a href="mailto:support@ishnoor.in">support@ishnoor.in</a>{" "}
                  within 12 hours of placing your order.
                </h3>
                <h3 className="para mb-3">
                  • You can track your delivery using the tracking ID sent to
                  your registered email address after dispatch.
                </h3>
                <h3 className="para mb-3">
                  • Deliveries occur on working days (Monday-Friday), with some
                  exceptions.
                </h3>
              </Grid>
              <Grid xs={12} className="mb-6">
                <h3 className="para_heading mb-3">
                  What If the Product Is Received in Damaged Condition?
                </h3>
                <h3 className="para mb-3">
                  If you receive a product that is damaged or if the packaging
                  appears tampered with, please do not accept the package and
                  return it to the delivery person. Contact us at +91-8175918027
                  or email{" "}
                  <a href="mailto:support@ishnoor.in">support@ishnoor.in</a>{" "}
                  with your Order ID. We will ensure that a brand new
                  replacement is sent to you at no additional cost. Please
                  ensure that the original product tag and packaging are intact
                  when returning the item.
                </h3>
              </Grid>
            </Grid>
            <Grid xs={12} className="df mb-6" gap={"12px"}>
              <Box className="con_box df" gap={"12px"}>
                <Box className="imgdiv">
                  <img
                    loading="eager"
                    src="images/contact_mail.svg"
                    alt="mail"
                  />
                </Box>
                <Box>
                  <h2 className="para_heading mb-1">Email</h2>
                  <h2 className="para">Support@ishnoor.in</h2>
                </Box>
              </Box>
              <Box className="con_box df" gap={"12px"}>
                <Box className="imgdiv">
                  <img loading="eager" src="images/contact_num.svg" alt="num" />
                </Box>
                <Box>
                  <h2 className="para_heading mb-1"> Contact us</h2>
                  <h2 className="para">+91 8175918027</h2>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default Shipping;
