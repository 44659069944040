// Redux/Slice/UserSlice.js
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  userInfo: {}, // Define the initial state with a default structure
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userInfo = action.payload;
    },
    logoutUser: (state) => {
      state.userInfo = {};
      Cookies.remove("ishnoorauthToken");
    },
    updateAddress: (state, action) => {
      state.userInfo.userDetail.deliveryAddress = action.payload;
    },
    updateWishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    updateCart: (state, action) => {
      state.cart = action.payload;
    },
    updateReturnOrderNumber: (state, action) => {
      state.returnOrderNumber = action.payload;
    },
    
  },
});

export const {
  setUserData,
  logoutUser,
  updateAddress,
  updateWishlist,
  updateCart,
  updateReturnOrderNumber,
} = userSlice.actions;

export default userSlice.reducer;
