import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Get } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import "../commonSCSS/common.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader/Loader";

const BlogDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const blogId = searchParams.get("blogId");
  const [blogDetails, setblogDetails] = useState();
  const [loading, setLoading] = useState(true);

  //Get new blog
  const getBlog = async () => {
    try {
      const response = await Get(`${URL_CONSTANTS.getBlogs}/${blogId}`);
      setblogDetails(response.response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Blogs:", error.message);
    }
  };

  useEffect(() => {
    getBlog()
  }, [])

  return (
    <>
      {loading && <Loader />}
      <Box className="mainWrapper">
        <Header />
        <Box className="blog_detailSection">
          <h4 className="heading mb-10">{blogDetails?.title}</h4>
          <Grid container padding={0} justifyContent={"center"}>
            <Grid lg={12}>
              <img
                loading="eager"
                className="blog_img mb-6"
                src={blogDetails?.image}
                alt="blg"
              />
            </Grid>
            <Grid className="blog_text" lg={12}>
              <p
                className="mb-3"
                dangerouslySetInnerHTML={{
                  __html: blogDetails?.description,
                }}
              >
                {/* {`${productItem?.description.substring(0, 250)}...`} */}
              </p>


              {/* <p className="para">

              </p> */}
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default BlogDetail;
