import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const SDSelectDropdown = ({
  label, // Label for the dropdown
  value, // The current selected value
  options = [], // Options array [{ value: '', label: '' }]
  onChange, // Callback function to handle value change
  helperText, // Optional helper text
  error, // Error state for validation
  required = false, // Required field state
  disabled = false, // Disable the dropdown
  className = "", // Custom CSS class
  fullWidth = true, // Full width of the dropdown
}) => {
  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      required={required}
      disabled={disabled}
      error={error}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={(e) => {
          const selectedValue = e.target.value; // Get the selected value
          onChange(selectedValue); // Pass the value to parent handler
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SDSelectDropdown;
