import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Delete, Post, Put } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { updateCart, updateWishlist } from "../Redux/Slice/UserSlice";
import { userRoutes } from "../Routes/UserRoutes";
import { sizeArray } from "../StaticData/static.enum";
import {
  calculateDiscountPercentage,
  formatCurrency,
} from "../Supporting files/HelpingFunction";
import QuickView from "./Modals/QuickView";

const ProductCard = ({ product }) => {
  const wishlistData = useSelector((state) => state?.user?.wishlist);
  const cartData = useSelector((state) => state.user.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quickViewData, setquickViewData] = useState([]);
  const [selectedVarientsId, setselectedVarientsId] = useState("");
  const [selectedSizesId, setselectedSizesId] = useState("");
  const [open, setOpen] = useState(false);
  let is_exist = cartData?.some(
    (productData) => productData.sizeId === product?.variants[0]?.sizes[0]?._id
  );
  let is_WishlistExist = wishlistData?.some(
    (productData) => productData.sizeId === product?.variants[0]?.sizes[0]?._id
  );
    const addToCart = async (productId, variantId, sizeId) => {
      let dto = {
        productId,
        variantId,
        sizeId,
        quantity: 1,
      };
      try {
        const response = await Post(
          `${URL_CONSTANTS.AddCartItems}`,
          JSON.stringify(dto)
        );
        dispatch(updateCart(response.response.products));

        toast.success("Cart updated successfully");
      } catch (error) {
        console.error("Error ", error);
      }
    };
    const removeFromCart = async (productId, variantId, sizeId) => {
      let dto = {
        productId,
        variantId,
        sizeId,
        quantity: 1,
      };
      try {
        const response = await Put(
          `${URL_CONSTANTS.removeCartItems}`,
          JSON.stringify(dto)
        );
        dispatch(updateCart(response.response.products));
        toast.success("Cart updated successfully");
      } catch (error) {
        console.error("Error ", error);
      }
    };
    const deleteWishList = async (productId, variantId, sizeId) => {
      let payload = {
        productId,
        variantId,
        sizeId,
      };

      try {
        const response = await Delete(
          `${URL_CONSTANTS.deleteWishlist}`,
          payload
        );
        toast.success("Wishlist updated successfully");
        dispatch(updateWishlist(response.response.products));
      } catch (error) {
        console.error("Error ", error.message);
      }
    };
    const addToWishlist = async (productId, variantId, sizeId) => {
      let dto = {
        productId,
        variantId,
        sizeId,
      };
      try {
        const response = await Post(
          `${URL_CONSTANTS.AddToWishlist}`,
          JSON.stringify(dto)
        );
        dispatch(updateWishlist(response.response.products));
        toast.success("Wishlist updated successfully");
      } catch (error) {
        console.error("Error ", error);
      }
    };
  return (
    <>
      <QuickView
        open={open}
        handleClose={() => setOpen(false)}
        productItem={quickViewData}
        selectedVarients={selectedVarientsId}
        selectedSizes={selectedSizesId}
      />
      <Grid xs={6} sm={6} md={4} lg={3} className="lookImg">
        <div className="actions">
          <img
            className="cart"
            loading="eager"
            src={is_exist ? "images/cart_full.png" : "images/cartWhite.svg"}
            onClick={() => {
              if (!is_exist) {
                addToCart(
                  product._id,
                  product?.variants[0]?._id,
                  product?.variants[0]?.sizes[0]?._id
                );
              } else {
                removeFromCart(
                  product._id,
                  product?.variants[0]?._id,
                  product?.variants[0]?.sizes[0]?._id
                );
              }
            }}
          />
          <img
            className="fav"
            loading="eager"
            src={is_WishlistExist ? "images/fav.svg" : "images/favWhite.svg"}
            onClick={() => {
              if (!is_WishlistExist) {
                addToWishlist(
                  product._id,
                  product?.variants[0]?._id,
                  product?.variants[0]?.sizes[0]?._id
                );
              } else {
                deleteWishList(
                  product._id,
                  product?.variants[0]?._id,
                  product?.variants[0]?.sizes[0]?._id
                );
              }
            }}
          />
        </div>
        <div className="product-image-container">
          <img
            className="mainPic"
            loading="eager"
            src={product.variants[0].images[0]}
            alt="look"
            onClick={() => {
              navigate(`${userRoutes.productDetail}?productId=${product._id}`);
            }}
          />

          {/* Quick View Eye Button */}
          <div
            className="quick-view"
            onClick={() => {
              setselectedVarientsId(product?.variants[0]?._id);
              setselectedSizesId(product?.variants[0]?.sizes[0]?._id);
              setquickViewData(product);
              setOpen(true);
            }}
          >
            <VisibilityIcon sx={{ fontSize: 30 }} />
            {/* <span className="quick-view-text">Quick View</span> */}
          </div>
        </div>
        <div className="desc">
          <p>{product.title}</p>
          <p>
            {formatCurrency(product.variants[0].sizes[0].price)}{" "}
            <span
              style={{
                fontSize: "15px",
                lineHeight: "32px",
                letterSpacing: "0.03em",
                color: "#A0A0A0",
                paddingLeft: "6px",
                textDecoration: "line-through",
              }}
            >
              {formatCurrency(product.variants[0].sizes[0].mrp)}{" "}
            </span>
            <span
              style={{
                marginLeft: 10,
                textDecoration: "none",
                color: "#8E282B",
                fontWeight: "600",
              }}
            >
              {calculateDiscountPercentage(
                product.variants[0].sizes[0].mrp,
                product.variants[0].sizes[0].price
              )}
              % off
            </span>
          </p>
        </div>
        <div className="size_buttons">
          {sizeArray.map((size, index) => {
            // Normalize size to lowercase for comparison
            const normalizedSize = size.toLowerCase();
            // Get all sizes from product.variants and normalize them to lowercase
            const availableSizes = new Set(
              product.variants.flatMap((variant) =>
                variant.sizes.map((sizeObj) => sizeObj.size.toLowerCase())
              )
            );
            // Check if the current size exists in the available sizes
            const isSelected = availableSizes.has(normalizedSize);
            return (
              <div
                key={index}
                className={`size_all ${
                  isSelected ? "selected" : "unavailable-size"
                }`}
              >
                {size}
              </div>
            );
          })}
        </div>
      </Grid>
    </>
  );
};

export default ProductCard;
