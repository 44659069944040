import { useState } from "react";
import crypto from "crypto-js";
import toast from "react-hot-toast";
import { Post } from "../Api/api";
import { URL_CONSTANTS } from "../Api/ApiUrl";
import { userRoutes } from "../Routes/UserRoutes";
import PasswordSuccess from "./Modals/PasswordSuccess";

const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.warn("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.warn("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

// Move getOptions function here so it can be used by RenderRazorpay
function getOptions(
  orderId,
  amount,
  currency,
  keyId,
  keySecret,
  handlePayment
) {
  return {
    key: keyId, // key id from props
    amount: 100 * amount, // Amount in lowest denomination from props
    currency, // Currency from props.
    name: "ishnoor", // Title for your organization to display in checkout modal
    order_id: orderId, // order id from props
    handler: (response) => {
      console.log("succeeded");
      console.log(response);

      const succeeded =
        crypto
          .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret)
          .toString() === response.razorpay_signature;

      if (succeeded) {
        handlePayment("succeeded", {
          orderId,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        });
      } else {
        handlePayment("failed", {
          orderId,
          paymentId: response.razorpay_payment_id,
        });
      }
    },
    modal: {
      confirm_close: true,
      ondismiss: async (reason) => {
        const {
          reason: paymentReason,
          field,
          step,
          code,
        } = reason && reason.error ? reason.error : {};
        if (reason === undefined) {
          console.log("cancelled");
          handlePayment("Cancelled");
        } else if (reason === "timeout") {
          console.log("timedout");
          handlePayment("timedout");
        } else {
          console.log("failed");
          handlePayment("failed", {
            paymentReason,
            field,
            step,
            code,
          });
        }
      },
    },
    retry: {
      enabled: false,
    },
    timeout: 900,
    theme: {
      color: "",
    },
  };
}

export async function RenderRazorpay(
  orderId,
  amount,
  keyId = "rzp_test_VQOxwDFbvAxZmK",
  keySecret = "JwiFLGqdKs9KroLCMafUB0wv",
  currency = "INR",
  handlePayment
) {
  let paymentId = null;
  let paymentMethod = null;

  // To load razorpay checkout modal script.
  const loadRazorPay = await loadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );

  if (!loadRazorPay) {
    console.log("Razorpay SDK failed to load. Are you online?");
    return;
  }

  const rzp1 = new window.Razorpay(
    getOptions(orderId, amount, currency, keyId, keySecret, handlePayment)
  );

  rzp1.on("payment.submit", (response) => {
    console.log(response);
    paymentMethod = response.method;
  });

  rzp1.on("payment.failed", (response) => {
    console.log(response);
    paymentId = response.error.metadata.payment_id;
  });

  rzp1.open();
}

const PaymentComponent = () => {
  const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);

  const handlePayment = async (status, orderDetails = {}) => {
    if (status === "succeeded") {
      let response = await Post(URL_CONSTANTS.paymentCapture, orderDetails);

      // Show the PasswordSuccess popup
      setShowPasswordSuccess(true);

      setTimeout(() => {
        toast.success("Your Payment has been Successfully Completed", {
          duration: 5000,
        });

        // Optionally redirect after some time
        window.location.href = userRoutes.orders;
      }, 2000);
    } else {
      toast.error("Your Payment has failed", {
        duration: 5000,
      });
    }
  };

  return (
    <>
      {/* Your Razorpay payment code */}
      <PasswordSuccess
        show={showPasswordSuccess}
        setShow={setShowPasswordSuccess}
      />
    </>
  );
};

export default PaymentComponent;
//do not delete this
// import crypto from "crypto-js";
// import toast from "react-hot-toast";
// import { Post } from "../Api/api";
// import { URL_CONSTANTS } from "../Api/ApiUrl";
// import { userRoutes } from "../Routes/UserRoutes";
// const loadScript = (src) =>
//   new Promise((resolve) => {
//     const script = document.createElement("script");
//     script.src = src;
//     script.onload = () => {
//       console.warn("razorpay loaded successfully");
//       resolve(true);
//     };
//     script.onerror = () => {
//       console.warn("error in loading razorpay");
//       resolve(false);
//     };
//     document.body.appendChild(script);
//   });

// export async function RenderRazorpay(
//   orderId,
//   amount,
//   keyId = "rzp_test_VQOxwDFbvAxZmK",
//   keySecret = "JwiFLGqdKs9KroLCMafUB0wv",
//   currency = "INR",
//   close
// ) {
//   let paymentId = null;
//   let paymentMethod = null;
//   // To load razorpay checkout modal script.
//   const loadRazorPay = await loadScript(
//     "https://checkout.razorpay.com/v1/checkout.js"
//   );

//   if (!loadRazorPay) {
//     console.log("Razorpay SDK failed to load. Are you online?");
//     return;
//   }
//   const rzp1 = new window.Razorpay(
//     getOptions(orderId, amount, currency, keyId, keySecret)
//   );

//   rzp1.on("payment.submit", (response) => {
//     console.log(response);
//     paymentMethod.current = response.method;
//   });

//   rzp1.on("payment.failed", (response) => {
//     console.log(response);
//     paymentId.current = response.error.metadata.payment_id;
//   });

//   rzp1.open();
// }

// const handlePayment = async (status, orderDetails = {}) => {
//   if (status === "succeeded") {
//     let response = await Post(URL_CONSTANTS.paymentCapture, orderDetails);
//  window.location.href = userRoutes.orders;

//     setTimeout(() => {
//           toast.success("Your Payment has been Successfully Completed", {
//       duration: 5000,
//     });
//     }, 2000);
//   } else {
//     toast.success("Your Payment has been failed", {
//       duration: 5000,
//     });
//   }
// };

// function getOptions(orderId, amount, currency, keyId, keySecret) {
//   return {
//     key: keyId, // key id from props
//     amount: 100 * amount, // Amount in lowest denomination from props
//     currency, // Currency from props.
//     name: "ishnoor", // Title for your organization to display in checkout modal
//     order_id: orderId, // order id from props
//     handler: (response) => {
//       console.log("succeeded");
//       console.log(response);
//       // paymentId.current = response.razorpay_payment_id;
//       const succeeded =
//         crypto
//           .HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret)
//           .toString() === response.razorpay_signature;
//       if (succeeded) {
//         handlePayment("succeeded", {
//           orderId,
//           paymentId: response.razorpay_payment_id,
//           signature: response.razorpay_signature,
//         });

//         // window.location.href = '/payment-success';
//       } else {
//         handlePayment("failed", {
//           orderId,
//           paymentId: response.razorpay_payment_id,
//         });
//       }
//     },
//     modal: {
//       confirm_close: true,
//       ondismiss: async (reason) => {
//         const {
//           reason: paymentReason,
//           field,
//           step,
//           code,
//         } = reason && reason.error ? reason.error : {};
//         if (reason === undefined) {
//           console.log("cancelled");
//           handlePayment("Cancelled");
//         } else if (reason === "timeout") {
//           console.log("timedout");
//           handlePayment("timedout");
//         } else {
//           console.log("failed");
//           handlePayment("failed", {
//             paymentReason,
//             field,
//             step,
//             code,
//           });
//         }
//       },
//     },
//     retry: {
//       enabled: false,
//     },
//     timeout: 900,
//     theme: {
//       color: "",
//     },
//   };
// }

// //   useEffect(() => {
// //     if(!enable){
// //     displayRazorpay(options);
// //     }
// //   }, []);
// //   return null;
// // };

// // export default RenderRazorpay;
